import React from 'react';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import * as qs from 'query-string';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import VideoContainer from './components/video-container';
import CommonUtility from './utils/common-utility';

export interface AppProps {
  location?: any;
}

export interface AppState {
}

export default class App extends React.Component <AppProps, AppState> {
  constructor(props: AppProps) {
    super(props);
    // TODO: Get roomName from query string
  }

  render() {
    return (
      <Router>
        <Container maxWidth='xl' >
          <Route exact path='/' component={this.Home} />
          <Route path='/r' component={this.Room} />
        </Container>
      </Router>
    );
  }

  Home() {
    const roomName = 'guestroom1'; // CommonUtility.getRandomString();
    return <VideoContainer roomName={roomName} />
  }
  
  Room (match: any) {
    // TODO: Use roomName from query
    const roomName = 'guestroom1'; // CommonUtility.getRandomString();
    return <VideoContainer roomName={roomName} />
  }
}
