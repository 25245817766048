import * as React from 'react';
import Box from '@material-ui/core/Box';
import CommonUtility from '../utils/common-utility';
import Video, { ConnectOptions, RemoteTrackPublication, RemoteParticipant, LocalParticipant, LocalTrack, LocalVideoTrack, LocalAudioTrack, Room, TrackPublication, Participant, TwilioError, LocalTrackPublication, LocalDataTrack } from 'twilio-video';
import VideoGrid from './video-grid';
import JoinGrid from './join-grid';
import { Twilio } from 'twilio';

// TODO: MOVE THIS TO DOTENV VARIABLE
// For production:
const TOKEN_URL: string = 'https://lemon-persian-6556.twil.io/capabilityToken';
const SMS_URL: string = 'https://lemon-persian-6556.twil.io/vidtactsms?roomName=';

// For Testing environment:
// const TOKEN_URL: string = 'https://lemon-persian-6556.twil.io/demoCapabilityToken';
// const SMS_URL: string = 'https://lemon-persian-6556.twil.io/demoSMS?roomName=';
const TEST_IDENTITY: string = 'Guest';

export interface UserVideoTile {
  participantId: string;
  ref: React.RefObject<HTMLVideoElement>;
  isLocal: boolean;
  isDominantSpeaker: boolean;
  audioTracks: Map<string, TrackPublication>;
  videoTracks: Map<string, TrackPublication>;
  label: string;
  videoToggle: any;
  audioToggle: any;
}

interface VideoContainerProps {
  roomName?: string;
}

interface VideoContainerState {
  microphoneDevice: any;
  speakerDevice: any;
  videoDevice: any;
  isDeviceReady: Boolean;
  isConnected: Boolean;
  isAudioMuted: Boolean;
  isVideoMuted: Boolean;
  activeRoom: Room;
  isJoiningRoom: Boolean;
  hasJoinedRoom: Boolean;
  localParticipant: LocalParticipant;
  participants: Map<string, RemoteParticipant>;
  dominantSpeaker: RemoteParticipant;
  localMediaAvailable: Boolean;
  localAudioTrack: LocalAudioTrack;
  localVideoTrack: LocalVideoTrack;
  capabilityToken: string;
  width: number;
  height: number;
}


export default class VideoContainer extends React.Component <VideoContainerProps, VideoContainerState> {

  private localMedia: React.RefObject<HTMLVideoElement>;
  private remoteMedia: Map<string, React.RefObject<HTMLVideoElement>> = new Map<string, React.RefObject<HTMLVideoElement>>();

  constructor(props: VideoContainerProps) {
    super(props);
    
    this.state = {
      microphoneDevice: null,
      speakerDevice: null,
      videoDevice: null,
      isDeviceReady: false,
      isConnected: false,
      isAudioMuted: false,
      isVideoMuted: false,
      activeRoom: {} as Room,
      isJoiningRoom: false,
      hasJoinedRoom: false,
      localParticipant: {} as LocalParticipant,
      participants: {} as Map<string, RemoteParticipant>,
      dominantSpeaker: {} as RemoteParticipant,
      localMediaAvailable: false,
      localAudioTrack: {} as LocalAudioTrack,
      localVideoTrack: {} as LocalVideoTrack,
      capabilityToken: '',
      width: 500,
      height: 500
    };

    this.localMedia = React.createRef();
  }

  componentDidMount () {
    this.updateDimensions();
    window.addEventListener('resize', this.updateDimensions);
    window.addEventListener('beforeunload', this.leaveRoomIfJoined);
  }

  componentWillUnmount () {
    window.removeEventListener('resize', this.updateDimensions);
    window.removeEventListener('beforeunload', this.leaveRoomIfJoined);
  }

  updateDimensions = () => {
    this.setState({
      ...this.state,
      width: window.innerWidth,
      height: window.innerHeight
    });
  }

  onJoinRoom = () => {
    this.setState({
      ...this.state,
      isJoiningRoom: true
    });

    this.setLocalTracks()
    .then((success: Boolean) => {
      if (success) {
        this.getCapabilityToken()
        .then((httpResponse: any) => {
          const token: string = httpResponse.token;
          if (token) {
            this.joinRoom(token);
          }
        })
        .catch((err: Error) => {
          console.error('error when retrieving capability token: ' + err);
        });
      }
      else {
        console.warn('not successful when setting local tracks');
      }
    })
    .catch((err: Error) => {
      console.error('Error when setting local tracks');
    });
  }

  getCapabilityToken = (): Promise<any> => {
    const localUserId: string = TEST_IDENTITY + CommonUtility.getRandomString();
    return new Promise((resolve, reject) => {
      CommonUtility.httpRequest(TOKEN_URL + '?identity=' + localUserId + '&room=' + this.props.roomName)
        .then((httpResponse: any) => {
          resolve(httpResponse);
        })
        .catch((err: any) => {
          console.error('Error when retrieving capability token: ' + JSON.stringify(err));
          reject(err);
        });
    });
  }

  // Get the Participant's Tracks.
  getRemoteTracks = (participant: any) => {
    if (participant.tracks) {
      return Array.from(participant.tracks.values()).filter((publication: any) => {
        return publication.track;
      }).map((publication: any) => {
        return publication.track;
      });
    }
    else {
      console.warn('DID NOT find remote tracks for participant: ' + participant);
      return [];
    }
  }

  // Attach a single track to the DOM
  attachTrack = (track: any, container: any) => {
    track.attach(container);
  }

  // Attach multiple tracks to the DOM.
  attachTracks = (tracks: any[], container: any) => {
    tracks.forEach((track: any) => {
      this.attachTrack(track, container);
    });
  }

  // Attach a remote participant's tracks to the DOM.
  attachParticipantTracks = (participant: RemoteParticipant, container: any) => {
    const tracks: any[] = Array.from(participant.tracks.values());
    this.attachTracks(tracks, container);
  }

  // detach a single track from the DOM
  detachTrack = (track: any) => {
    track.detach().forEach((detachedElement: any) => {
      detachedElement.remove();
    });
  }

  // Detach a remote participant's tracks from the DOM.
  detachParticipantTracks = (participant: any) => {
    const tracks: Video.RemoteTrack[] = this.getRemoteTracks(participant);
    if (tracks.length > 0) {
      tracks.forEach(this.detachTrack);
    }
  }

  // Detach local tracks 
  detachTracks = (tracks: LocalTrack[]) => {
    tracks.forEach((track: LocalTrack) => {
      this.detachTrack(track);    
    });
    
    this.setState({
      ...this.state,
      localMediaAvailable: false,
      localAudioTrack: {} as LocalAudioTrack,
      localVideoTrack: {} as LocalVideoTrack
    });
  }

  // Get the actual video tracks
  getOrCreateLocalTracks = (): Promise<{videoTrack: LocalVideoTrack, audioTrack: LocalAudioTrack}> => {
    return new Promise((resolve, reject) => {
      if (this.state.localMediaAvailable) {
        console.warn('already have localTracks, not creating them');
        const localAudio: LocalAudioTrack = this.state.localAudioTrack as LocalAudioTrack;
        const localVideo: LocalVideoTrack = this.state.localVideoTrack as LocalVideoTrack;
        Promise.resolve({localVideo, localAudio});
      }
      else {
        // const localTrackOptions: Video.CreateLocalTracksOptions = {audio: true, video: { width: 720 } }
        Video.createLocalTracks()
        .then((tracks: LocalTrack[]) => {
          let videoTrack: LocalVideoTrack = {} as LocalVideoTrack;
          let audioTrack: LocalAudioTrack = {} as LocalAudioTrack;
          let dataTrack: LocalDataTrack = {} as LocalDataTrack;
          tracks.forEach((track: Video.LocalTrack) => {
            if (track.kind === 'video') {
              videoTrack = track;
            }
            else if (track.kind === 'audio') {
              audioTrack = track;
            }
            else {
              // TODO: look at using data track
              dataTrack = track;
            }
          });
          this.setState({
            ...this.state,
            localVideoTrack: videoTrack,
            localAudioTrack: audioTrack,
            localMediaAvailable: true
          });
          resolve({videoTrack, audioTrack});
        })
        .catch((err: Error) => {
          console.error('Error when attempting to create local tracks: ' + err);
        });
      }
    });
  }

  // Attach local user's video tracks to DOM
  setLocalTracks = (): Promise<Boolean> => {
    // TODO: Separate out attach local audio track and video track
    return this.getOrCreateLocalTracks()
      .then((tracks: {videoTrack: LocalVideoTrack, audioTrack: LocalAudioTrack}) => {
        const localVideo = document.getElementById('video-local-media');
        const localAudio = document.getElementById('audio-local-media');
        this.attachTrack(tracks.videoTrack, this.localMedia.current);
        this.attachTrack(tracks.audioTrack, localAudio);
        return true;
      })
      .catch((err) => {
        console.warn('Error when setting local tracks');
        console.warn(err);
        return false;
      });
  }

  // Connect participants already in room
  connectRemoteParticipants = (roomParticipants: RemoteParticipant[]) => {
    roomParticipants.forEach((participant: RemoteParticipant) => {
      this.onParticipantConnected(participant);
    });
  }

  joinRoom = (capabilityToken: string) => {
    if (this.state.localAudioTrack || this.state.localVideoTrack) {
      let connectOptions: ConnectOptions = {
        name: TEST_IDENTITY,
        logLevel: 'debug',
        tracks: [this.state.localVideoTrack, this.state.localAudioTrack]
      };

      Video.connect(capabilityToken, connectOptions)
      .then((room: Video.Room) => { 
        this.onRoomJoined(room);
      })
      .catch((err: any) => {
        console.warn('Could not connect to Twilio: ' + err.message);
      });
    }
    else {
      console.warn('Not connecting to room!');
    }
  }

  messageOrganizer = () => {
    CommonUtility.httpRequest(SMS_URL + this.props.roomName)
    .then((response: any) => {
      console.log('Organizer has been contacted by SMS');
    });
  }

  onRoomJoined = (room: Video.Room) => {
    console.warn('room joined!');
    const activeRoom: Video.Room = room;
    activeRoom.on('reconnecting', this.onRoomReconnecting);
    activeRoom.on('reconnected', this.onRoomReconnected);
    activeRoom.on('disconnected', this.onRoomLeft);
    activeRoom.on('participantConnected', this.onParticipantConnected);
    activeRoom.on('participantDisconnected', this.onParticipantDisconnected);
    activeRoom.on('dominantSpeakerChanged', this.onDominantSpeakerChanged);

    const roomParticipants = activeRoom.participants;
    const roomParticipantArray = Array.from(roomParticipants.values());

    this.connectRemoteParticipants(roomParticipantArray);
    this.messageOrganizer();

    this.setState({
      ...this.state,
      activeRoom: activeRoom,
      isJoiningRoom: false,
      hasJoinedRoom: true,
      participants: roomParticipants
    });
  }

  onTrackPublished = (publication: RemoteTrackPublication, participantId: string) => {
    publication.on('subscribed', (track: Video.RemoteTrack) => {
      const elementIdString: string = `${publication.kind}-${participantId}`;
      const container = document.getElementById(elementIdString);
      this.attachTrack(track, container);
      track.on('disabled', this.onRemoteParticipantMuted);
      track.on('enabled', this.onRemoteParticipantUnMuted);

      // TODO: Make this more robust to handle UI changes - currently have to attach local tracks after video grid is re-ordered
      if (this.state.hasJoinedRoom && this.state.localMediaAvailable) {
        this.setLocalTracks();
      }
    });
    
    publication.on('unsubscribed', (track: Video.RemoteTrack) => {
      track.off('disabled', this.onRemoteParticipantMuted);
      track.off('enabled', this.onRemoteParticipantUnMuted);
      this.detachTrack(track);
    });
  }

  onRemoteParticipantMuted = (track: any) => {
    console.log('Remote participant muted');
    // Update UI when remote participant muted
  }

  onRemoteParticipantUnMuted = (track: any) => {
    console.log('Remote participant unmuted');
    // Upddate UI when remote participant unmuted
  }

  trackUnpublished = (publication: any) => {
    console.warn(publication.kind + ' track was unpublished.');
    if (this.state.hasJoinedRoom && this.state.localMediaAvailable) {
      this.setLocalTracks();
    }
    // TODO: Detach the tracks
  }

  onParticipantConnected = (participant: Video.Participant) => {
    console.log('Participant joined: ' + participant.identity);
    
    participant.tracks.forEach((publication: any) => {
      this.onTrackPublished(publication, participant.identity);
    });

    participant.on('trackPublished', (publication: RemoteTrackPublication) => {
      // Not actually using this at the moment
      if (publication.isSubscribed) {
        console.log('Subscribed to participant track');
        return;
      }
    });

    participant.on('trackUnpublished', this.trackUnpublished);
    
    this.setState({
      ...this.state,
      participants: this.state.activeRoom.participants
    });
  }

  onParticipantDisconnected = (participant: RemoteParticipant) => {
    console.log('Participant left the room: ' + participant.identity);
    this.detachParticipantTracks(participant);
    if (this.state.activeRoom.participants.size === 0) {
      // TODO: detect if local video tile is removed, and reconnect it
      this.setLocalTracks();
    }
    this.setState({
      ...this.state,
      participants: this.state.activeRoom.participants
    });
  }

  onRoomDisconnected = (room: Video.Room, error: TwilioError) => {
    // Not currently using this
    console.warn('Disconnected from the room');
    if (error.code === 20104) {
      console.error('Signaling reconnection failed due to expired AccessToken!');
    } else if (error.code === 53000) {
      console.error('Signaling reconnection attempts exhausted!');
    } else if (error.code === 53204) {
      console.error('Signaling reconnection took too long!');
    }
    this.onRoomLeft();
  }

  onRoomLeft = () => {
    console.error('Left the room');
    this.detachTrack(this.state.localAudioTrack);
    this.detachTrack(this.state.localVideoTrack);

    this.setState({
      ...this.state,
      activeRoom: {} as Room,
      isJoiningRoom: false,
      hasJoinedRoom: false,
      localMediaAvailable: false,
      participants: {} as Map<string, RemoteParticipant>,
      localAudioTrack: {} as LocalAudioTrack,
      localVideoTrack: {} as LocalVideoTrack
    });
  }

  onRoomReconnecting = (error: TwilioError) => {
    // TODO: Use this info to display reconnecting message to UI
    console.warn('Reconnecting to the room');
    if (error.code === 53001) {
      console.error('Reconnecting your signaling connection!', error.message);
    } else if (error.code === 53405) {
      console.error('Reconnecting your media connection!', error.message);
    }
  }

  onRoomReconnected = () => {
    console.warn('Reconnected to room');
    // TODO: use this info to display reconnected message to UI
  }

  onDominantSpeakerChanged = (dominantSpeaker: RemoteParticipant) => {
    if (dominantSpeaker) {
      console.log('updating dominant speaker to ' + dominantSpeaker.identity);
      this.setState({
        ...this.state,
        dominantSpeaker: dominantSpeaker
      });
    }
  }

  leaveRoomIfJoined = () => {
    console.log('Leaving room');
    this.stopLocalAudioTrack();
    this.stopLocalVideoTrack();
    if (this.state.activeRoom && this.state.activeRoom.state === 'connected') {
      this.state.activeRoom.disconnect();
    }
  }

  stopLocalVideoTrack = () => {
    if (this.state.localVideoTrack && this.state.localVideoTrack.isStarted) {
      console.log('stopping and detaching local video track');
      this.state.localVideoTrack.stop();
      this.state.localVideoTrack.detach();
    }
  }

  stopLocalAudioTrack = () => {
    if (this.state.localAudioTrack && this.state.localAudioTrack.isStarted) {
      console.log('stopping and detaching local audio track');
      this.state.localAudioTrack.stop();
      this.state.localAudioTrack.detach();
    }
  }

  muteAudio = () => {
    console.log('muting audio');
    // this.stopLocalAudioTrack();
    this.state.localAudioTrack.disable();
    this.setState({
      ...this.state,
      isAudioMuted: true
    });
  }

  unMuteAudio = () => {
    console.log('unmuting audio');
    this.state.localAudioTrack.enable();
    // this.startLocalAudioTrack();
    this.setState({
      ...this.state,
      isAudioMuted: false
    });
  }

  muteVideo = () => {
    console.log('muting video');
    // this.stopLocalVideoTrack();
    this.state.localVideoTrack.disable();
    this.setState({
      ...this.state,
      isVideoMuted: true
    });
  }

  unMuteVideo = () => {
    console.log('unmuting video');
    // this.startLocalVideoTrack();
    this.state.localVideoTrack.enable();
    this.setState({
      ...this.state,
      isVideoMuted: false
    });
  }   

  getLocalUserVideoTile = () => {
    const localUserTile = {
      participantId: 'local-media',
      isLocal: true,
      isDominantSpeaker: true,
      audioTracks: this.state.localParticipant.audioTracks,
      videoTracks: this.state.localParticipant.videoTracks,
      ref: this.localMedia,
      label: 'My Video',
      videoToggle: this.state.isVideoMuted ? this.unMuteVideo : this.muteVideo,
      audioToggle: this.state.isAudioMuted ? this.unMuteAudio : this.muteAudio
    };
    return localUserTile;
  }

  getDominantSpeakerVideoTile = () => {
    let dominantSpeakerTile: UserVideoTile;
    const dominantSpeakerId: string = (this.state.dominantSpeaker) ? this.state.dominantSpeaker.identity : '';

    if (dominantSpeakerId && this.state.participants && this.state.participants.size > 0) {
      this.state.participants.forEach((participant: Participant, key: string) => {
        if (dominantSpeakerId === participant.identity) {
          const dominantRef: React.RefObject<HTMLVideoElement> = React.createRef();
          this.remoteMedia.set(key, dominantRef);
          dominantSpeakerTile = {
            participantId: participant.identity,
            isLocal: false,
            isDominantSpeaker: true,
            audioTracks: participant.audioTracks,
            videoTracks: participant.videoTracks,
            ref: dominantRef,
            label: 'Active Speaker',
            videoToggle: null,
            audioToggle: null
          };
          return dominantSpeakerTile;
        }
        else {
          console.warn('did NOT find dominant speaker in participant list!');
        }
      });
    }
    return null;
  }

  getRemoteParticipantVideoTiles = () => {
    let userVideoTiles: UserVideoTile[] = [] as UserVideoTile[];
    let remoteParticipantIndex: number = 0;

    if (this.state.participants && this.state.participants.size > 0) {
      this.state.participants.forEach((participant: RemoteParticipant, key: string) => {
        if (!this.state.dominantSpeaker || participant.identity !== this.state.dominantSpeaker.identity) {
          if (participant.videoTracks.size > 0) {
            const remoteRef: React.RefObject<HTMLVideoElement> = React.createRef();
            this.remoteMedia.set(key, remoteRef);
            const remoteUserTile = {
              participantId: participant.identity,
              isLocal: false,
              isDominantSpeaker: false,
              audioTracks: participant.audioTracks,
              videoTracks: participant.videoTracks,
              ref: remoteRef,
              label: 'Remote Participant ' + remoteParticipantIndex,
              videoToggle: null,
              audioToggle: null
            };
            userVideoTiles.push(remoteUserTile);
          }
          else {
            return null;
          }
        }
        remoteParticipantIndex++;
      });
    }
    return userVideoTiles;
  }

  render () {
    let audioToggle: any = null;
    let videoToggle: any = null;

    if (this.state.localMediaAvailable) {
      audioToggle = (this.state.isAudioMuted) ? this.unMuteAudio : this.muteAudio;
      videoToggle = (this.state.isVideoMuted) ? this.unMuteVideo : this.muteVideo;
    }
    // TODO: Audio / Video Preview / Test buttons for testing local video and audio
      
    return (
      <Box width={1} height={1}>
        {(this.state.isJoiningRoom || this.state.hasJoinedRoom) ? 
          <VideoGrid
            height={this.state.height}
            localUserTile={this.getLocalUserVideoTile()}
            dominantSpeakerTile={this.getDominantSpeakerVideoTile()}
            participantTiles={this.getRemoteParticipantVideoTiles()}
            leaveRoom={this.leaveRoomIfJoined}
            isAudioMuted={this.state.isAudioMuted}
            audioToggle={audioToggle}
            isVideoMuted={this.state.isVideoMuted}
            videoToggle={videoToggle}
          /> :
          <JoinGrid
            gridHeight={this.state.height}
            joinRoom={this.onJoinRoom}
            isAudioMuted={this.state.isAudioMuted}
            audioToggle={audioToggle}
            isVideoMuted={this.state.isVideoMuted}
            videoToggle={videoToggle}
          />
        }
      </Box>
    );
  }
}

// Not using this for now, may want to re-implement if needed:
/*
  startLocalVideoTrack = () => {
    console.warn('creating localVideoTrack');
    Video.createLocalVideoTrack()
    .then(
      async (localVideoTrack: LocalVideoTrack) => {
        console.warn('created local video track, and now publishing it');
        this.state.activeRoom.localParticipant.publishTrack(localVideoTrack)
        .then((publishedTrack: LocalTrackPublication) => {
          const localMedia = document.getElementById('video-local-media');
          this.setState({
            ...this.state,
            localVideoTrack: localVideoTrack
          });
          this.attachTrack(localVideoTrack, localMedia);
        });
    });
  }

  startLocalAudioTrack = () => {
    console.warn('creating local audio track');
    Video.createLocalAudioTrack()
    .then(
      async (localAudioTrack: LocalAudioTrack) => {
        console.warn('created local audio track, and now publishing it');
        this.state.activeRoom.localParticipant.publishTrack(localAudioTrack)
        .then((publishedTrack: LocalTrackPublication) => {
          const localMedia = document.getElementById('audio-local-media');

          this.setState({
            ...this.state,
            localAudioTrack: localAudioTrack
          });
          this.attachTrack(localAudioTrack, localMedia);
        });
    });
  }


*/